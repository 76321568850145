.ant-slider-tooltip > .ant-tooltip-content >.ant-tooltip-arrow {
    /* display: none; */
    width: 16px;
    height: 16px;
}

.ant-slider-tooltip > .ant-tooltip-content >.ant-tooltip-inner {
    padding: unset;
    /* box-shadow: unset; */
    border-width: 1px;
    min-height: unset;
}

.ant-slider-tooltip, .ant-tooltip-placement-top {
    padding-bottom: 0px !important;
    margin-top: 5px !important;
}