.CustomModal .ant-form-item {
  margin-bottom: 0px !important;
}

.CustomModal .ant-form-item-label {
  padding: 0 !important;
}

.CustomModal .ant-modal-body {
  padding-top: 0px !important;
}

.CustomModal .ant-divider-horizontal {
  margin-top: 0px !important;
  margin-bottom: 4px !important;
}

.ant-picker-input > input[disabled], .ant-input[disabled], .ant-input-affix-wrapper-disabled, .ant-select-disabled > .ant-select-selector > .ant-select-selection-item {
  color: rgba(0,0,0,0.8) !important 
}

.customSwitch {
  /* background-color: rgb(206,36,36) !important; */
  opacity: unset !important;
}