.ant-table-tbody > tr > td {
  overflow-wrap: break-word;
  padding: 4px;
}

.ant-table-thead > tr > th {
  overflow-wrap: break-word;
  padding: 4px;
  font-size: medium;
}

.ant-select-dropdown  {
  width: fit-content !important;
}