.noti-modal {
  padding-bottom: 0px !important;
}

.noti-modal .ant-modal-body {
  padding: 12px;
}

.ant-menu-item {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ant-list-item-meta {
  margin-bottom: 0px !important;
}