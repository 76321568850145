/* .CabinetStatisticModal > .ant-tabs-tab .anticon {
    margin-right: 0px !important;
}

.CabinetStatisticModal {
    width: min-content !important;
}

.CabinetStatisticModal > .ant-table-cell .indent-level-3 {
    padding-left: 0px !important;
}

.CabinetStatisticModal > .ant-table-row-level-0, .ant-table-row-level-1, .ant-table-row-level-2 {
    cursor: pointer !important;
} */

.BulkControlModal .ant-modal-body {
    padding-top: 0px !important;
  }

.BulkControlModal .ant-form-item {
    margin-bottom: 0px !important;
  }

.BulkControlModal {
    width: max-content !important;
}